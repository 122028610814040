import { API } from "./api";
import { api_services } from "./config";

export const GET_CLASSROOMS = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/classrooms?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
    }
}


const POST_classrooms = async (data) => {

    try {
        const result = await api_services('/classrooms', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const updateClassrooms = async (id, data) => {    
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getOne = async (id) => {
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/classrooms/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer {token}`
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async () => {
    try {
        const result = await api_services(`/classrooms/restore_all`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreOneDeleted = async (id) => {
    try {
        const result = await api_services(`/classrooms/restore_one/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
const DeleteOne = async (id) => {
    console.log(id);
    
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const classroomService = {
    POST_classrooms,
    getOne,
    DeleteOne,
    updateClassrooms,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted
}