import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { courseService, Environment, GET_COURSES_DELETED, Head } from '../../shared'
import {
    Box, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';

export function CourseDeleted() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [Change, setChange] = React.useState(false);
    const navigate = useNavigate()
    
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuthContext()
  const [totalAccount, setTotalAccount] = React.useState(0);

    const search = useMemo(() => {
        return searchParams?.get('search') || ''
    }, [searchParams])


    const page = useMemo(() => {
        return searchParams?.get('page') || 1
    }, [searchParams])

    React.useEffect(() => {
        const findAllCoursesDeleted = async () => {
            try {
                setLoading(true);
                setError(null);
                const { url, options } = GET_COURSES_DELETED(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
                const response = await fetch(url, options);
                const result = await response.json();
                setTotalAccount(result.meta.total)
                setData(result.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        findAllCoursesDeleted();
        setChange(false)
    }, [search, page, Change]);

    const restoreOne = async (id) => {
        const result = await courseService.restoreOneDeleted(id);
        if (result instanceof Error) {
            setError(result.message);
            toast.error(result.message)
            setLoading(false);
        } else {
        toast.success('Curso restaurado com sucesso!', {
            position: 'top-right',
            className: 'custom-toast',
        })
        navigate('/academic/courses/erased')
        setLoading(false);
        }
    }

    const restoreAllCourse = async () => {
        setLoading(true);
        setError(null);
        const response = await courseService.restoreAllDeleted();
        if (response instanceof Error) {
            console.log(response)
            setError(response.message);
            toast.error(response.message)
            setLoading(false);
        } else {

            console.log(response);
            toast.success('Todos os cursos restaurados com sucesso!', {
                position: 'top-right',
                className: 'custom-toast',
            })
            navigate('/academic/courses')
            setLoading(false);
        }
    }
    return (
        <MenuDrawer page='Cursos Apagados'>
            <Head title='Cursos Apagados' />
            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <Box m={1} >
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '-3rem'}}>
                            <Paper
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: '#ededed', marginTop: '-4rem' }}
                            >
                                <InputBase
                                    size='small'
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Pesquisar"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                                    value={search ?? ''}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            <button className='border-none ml-1 btn-success' onClick={() => restoreAllCourse()}>
                                Restaurar Todos os Cursos <i className='fas fa-refresh ml-1'></i>
                            </button>
                        </div>
                    </Box>
                    <Divider />
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={0} sx={{ flexGrow: 1 }} style={{ border: '1px solid #ededed' }}>
                                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'>Curso</TableCell>
                                            <TableCell className='title-table text-center'>Descrição</TableCell>
                                            <TableCell className='title-table text-center'>Anos</TableCell>
                                            <TableCell className='title-table text-center'>Estado</TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.length > 0 ? data.map((item) => (
                                            <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className='text-center cel-name'>{item.name}</TableCell>
                                                <TableCell className='text-justify'>{item.description}</TableCell>
                                                <TableCell className='text-center cel-years'>{item.years}</TableCell>
                                                <TableCell className='text-center'>
                                                    <p className='curso-inactivo'>Apagado</p>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                        <Typography className='btn-icon btn-edit' onClick={() => restoreOne(item.id)}>
                                                            <i className="fa-solid fa-refresh"></i>
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <Typography width={250} color={'grayText'}>Lista de Cursos Apagados Vazia</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        }

                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <LinearProgress color='secondary' />
                                                </TableCell>
                                            </TableRow>
                                        )}

                                    </TableBody>
                                    <TableFooter>
                                        {error && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center"><Typography width={250}>{error}</Typography></TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                    page={Number(page)} onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}
