import React, { useState } from 'react'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Container, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '../form';
import { classroomService, GET_COURSES, GET_LEVEL, GET_LOCATION, Head, usersServices } from '../../shared'
import { useAuthContext } from '../../shared/hooks'
import { MenuDrawer } from '../Dashboard';
import { useParams } from 'react-router-dom';
import { ErrorResponse, SuccessResponse } from '../Modals';

const schema = yup.object().shape({
    name: yup.string().required('Campo obrigatorio'),
    description: yup.string().required('Campo obrigatorio'),
    min_students: yup.number().required('Campo obrigatorio'),
    max_students: yup.number().required('Campo obrigatorio'),
    location_id: yup.number().required('Campo obrigatorio'),
    academic_level_id: yup.number().required('Campo obrigatorio'),
    course_id: yup.number().required('Campo obrigatorio'),
    user_id: yup.number().required('Campo obrigatorio'),
});

export function AddAndUpdateClasses() {
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { id } = useParams();
    const { token } = useAuthContext()
    const [ClassRoomUpdate, setClassRoomUpdate] = React.useState(undefined);

    const [MsgSuccess, setMsgSuccess] = useState(undefined);
    const [MsgError, setMsgError] = useState(undefined);
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            description: '',
            min_students: undefined,
            max_students: undefined,
            location_id: undefined,
            academic_level_id: undefined,
            course_id: undefined,
            user_id: undefined,
        }
    });

    const [Course, setCourse] = useState([]);
    const [functionary, setFunctionary] = useState([]);
    const [Location, setLocation] = useState([]);
    const [Level, setLevel] = useState([]);
    


  React.useEffect(() => {
    const findAllLevels = async () => {
      try {
        setLoading(true);
        const { url, options } = GET_LEVEL();
        const response = await fetch(url, options);
        const result = await response.json();
        setLevel(result.data);
      } catch (error) {
        setMsgError(error.message)
      } finally {
        setLoading(false);
      }
    }
    findAllLevels();
  }, []);

    React.useEffect(() => {
        const findAllCourses = async () => {
          try {
            setLoading(true);
            const { url, options } = GET_COURSES();
            const response = await fetch(url, options);
            const result = await response.json();
            setCourse(result.data);
          } catch (error) {
            setMsgError(error.message)
          } finally {
            setLoading(false);
          }
        }
        findAllCourses();
      }, []);

      React.useEffect(() => {
        const findAllLocations = async () => {
          try {
            setLoading(true);
            const { url, options } = GET_LOCATION();
            const response = await fetch(url, options);
            const result = await response.json();
            setLocation(result.data);
          } catch (error) {
            setMsgError(error.message)
          } finally {
            setLoading(false);
          }
        }
        findAllLocations();
      }, []);


      React.useEffect(() => {
        const findAllFunctionary = async () => {
          try {
            setLoading(true);
            const response = await usersServices.getAll(token);
            const result = await response.json();
            setFunctionary(result.data);
          } catch (error) {
            setMsgError(error.message)
          } finally {
            setLoading(false);
          }
        }
        findAllFunctionary();
      }, []);


      React.useEffect(() => {
        if (id && typeof Number(id) === 'number') {
            setIsUpdate(true)
            classroomService.getOne(id).then((data) => {
                setClassRoomUpdate(data.data)
            }).catch(err => {
                console.log({ err });
            })
        }
    }, [id, isUpdate])

    

    React.useEffect(() => {        
        if (ClassRoomUpdate) {
            setValue('name', ClassRoomUpdate.name);
            setValue('description', ClassRoomUpdate.description);
            setValue('min_students', ClassRoomUpdate.min_students);
            setValue('max_students', ClassRoomUpdate.max_students);
            setValue('status', ClassRoomUpdate.status);
            setValue('user_id', ClassRoomUpdate.user_id);
            setValue('academic_level_id', ClassRoomUpdate.academic_level_id);
            setValue('location_id', ClassRoomUpdate.location_id);
            setValue('course_id', ClassRoomUpdate.course_id);
        }

    }, [ClassRoomUpdate]);

    const cleanData = () => {
        
        setValue('name', '');
        setValue('description', '');
        setValue('min_students', '');
        setValue('max_students', '');
        setValue('status', '');
        setValue('user_id', '');
        setValue('academic_level_id', '');
        setValue('location_id', '');
        setValue('course_id', '');
    }

    const submitData = async (data) => {
        setLoading(true)
        try {
            const response = await classroomService.POST_classrooms(data);
            cleanData()
            console.log(response);
            setMsgSuccess('Turma cadastrado com sucesso.');
        } catch (error) {
            console.log(error)
            setMsgError(error.error)
        }finally{
            setLoading(false)
        }
    }
 console.log({errors});
 
    const UpdateData = async (data) => {
        setLoading(true)        
        try {
            const response = await classroomService.updateClassrooms(id, data);
            console.log(response);
            cleanData();
            setMsgSuccess('Turma actualizado com sucesso.');
        } catch (error) {
            console.log(error)
            setMsgError(error.error)
        }finally{
            setLoading(false)
        }
    }

    return (
        <MenuDrawer page='Turmas'>
            <Head title={id ? 'Actualizar dados de turma' : 'Cadastra turma'} />
            {!!MsgSuccess && <SuccessResponse title={MsgSuccess} setClose={setMsgSuccess} urlNext='/academic/classes' />}
            
            {!!MsgError && <ErrorResponse title={MsgError} setClose={setMsgError}/>}
           
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(UpdateData) : handleSubmit(submitData)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Nome da turma</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Ex.: TI10AT"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Coordenador</InputLabel>
                                    <Controller
                                        control={control}
                                        name='user_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            
                                            <>
                                            <FormControl fullWidth 
                                            margin="normal">

                                            <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                fullWidth
                                                onChange={onChange} value={value ?? ''}
                                                color='primary'
                                                error={!!errors.user_id}
                                                label='Selecione'
                                            >
                                                {functionary.length > 0 && functionary.map(fun => (<MenuItem key={fun.id} value={fun.id}>{fun.name}</MenuItem>))}
                                            </Select>
                                            </FormControl>
                                        </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Nº minímo de estudantes</InputLabel>
                                    <Controller
                                        control={control}
                                        name='min_students'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                type={'number'}
                                                placeholder={"Digite nº minímo de estudantes que esta turma pode ter"}
                                                color='primary'
                                                error={!!errors.min_students}
                                                helperText={errors.min_students ? errors.min_students.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Nº maximo de estudantes</InputLabel>
                                    <Controller
                                        control={control}
                                        name='max_students'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                type={'number'}
                                                placeholder={"Digite nº maximo de estudantes que esta turma pode ter"}
                                                color='primary'
                                                error={!!errors.max_students}
                                                helperText={errors.max_students ? errors.max_students.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} >
                                        <Controller
                                            control={control}
                                            name='location_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                
                                                <>
                                                    <InputLabel className='label'>localização</InputLabel>
                                                    <FormControl fullWidth 
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.location_id}
                                                        label='Selecione'
                                                    >
                                                        {Location.length > 0 && Location.map(loc =>  <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>)}
                                                    </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <Controller
                                            control={control}
                                            name='course_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                
                                                <>
                                                    <InputLabel className='label'>Curso</InputLabel>
                                                    <FormControl fullWidth 
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.course_id}
                                                        label='Selecione'
                                                    >
                                                        {Course.length > 0 && Course.map(course =>  <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>)}
                                                    </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Controller
                                            control={control}
                                            name='academic_level_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                
                                                <>
                                                    <InputLabel className='label'>Classe</InputLabel>
                                                    <FormControl fullWidth 
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.academic_level_id}
                                                        label='Selecione'
                                                    >
                                                        {Level.length > 0 && Level.map(lev =>  <MenuItem key={lev.id} value={lev.id}>{lev.name}</MenuItem>)}
                                                    </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Descrição</InputLabel>
                                    <Controller
                                        control={control}
                                        name='description'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                multiline
                                                rows={4}
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite a descrição da turma'}
                                                error={!!errors.description}
                                                helperText={errors.description ? errors.description.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} mb={2} >
                                    {isUpdate ?
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                fullWidth
                                                type='submit'
                                                disabled={loading}
                                                endIcon={loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon>}
                                                onSubmit={handleSubmit(UpdateData)}
                                                sx={{ py: 1.9 }}
                                            >
                                                Actualizar
                                            </Button>
                                        </>
                                        :
                                        <Button
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            disabled={loading}
                                            onSubmit={handleSubmit(submitData)}
                                            endIcon={loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon>}
                                            sx={{ py: 1.9 }}
                                        >
                                            Guardar
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}