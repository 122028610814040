
import React, { useState } from 'react'
import { Autocomplete, Box, Checkbox, Icon, InputLabel, TextField, Typography } from '@mui/material'
import { ButtonIndicator, Input} from '../form'
import { contactServices } from '../../shared';
import { PnoWrap } from '../paragraphy';


export function  AddAndUpdateContact({user = null, countries}) {
    const [ contacts, setContacts] = useState([{
            country_id: '',
            contact: '',
            full_phone: '',
            
    }])
    const [ contactsType, setContactsType] = useState([{
        is_email: false,
        is_main: false,
        is_home_phone: false,
        is_owner: false,
    }])

    const [countrySelected, setCountrySelected] = useState([]);  

    const countryChange = (value, id) => {
        
        let aux = countries[countries.findIndex((v) => {
            if(v.phone === value?.toString()) { 
                return v
            }})]

        setCountrySelected(old => old.length > 0 ? [...old, aux]: [aux]);
        const newItems = [...contacts];
        if(aux){
            newItems[id]['country_id'] = aux.phone;
        }else{
            countrySelected.splice(id, 1);
            setCountrySelected(countrySelected);
            newItems[id]['country_id'] = ''
        }
        setContacts(newItems);
    }

    const addNewContact = () => {
        setContacts(old => [...old, {
            country_id: '',
            contact: '',
            full_phone: '',
        }])
        setContactsType(old => [...old, {
            is_email: false,
            is_main: false,
            is_home_phone: false,
            is_owner: false,
        }])
    }

    const handleChange = (index, event) => {
        const newItems = [...contacts];
        newItems[index][event.target.name] = event.target.value;
       
        setContacts(newItems);
    };

    const handleChangeCheck = (index, event) => {
        const newItems = [...contactsType];
        newItems[index][event.target.name] = event.target.checked;
       
        setContactsType(newItems);
    };

    const handleSubmit = async () => {
        try {
            const {id} = await contactServices.post(contacts);
            const clearContact = contactsType;

            const contactJoined = clearContact.map(el => {
                return {
                    entity: user.id,
                    contact_id: id,
                    ...el,
                }
            })
             const result = await contactServices.postContactType(contactJoined)
             console.log('aaaaaaaaa\n\n', {result});
        } catch (error) {
            
        }
    }
    const removeOne = (_id) => {
        contacts.splice(_id, 1);
        setCountrySelected(old => old.filter((_,idOld) => _id !== idOld));
        setContacts(contacts)
    }

  return (
    <Box >
             <Box >

                <Box
                    width={'100%'}
                    display={'flex'} 
                    flexDirection={'column'}
                >
                    {/* <Box
                        display={'flex'} 
                        justifyContent={'space-between'}
                        mb={2}
                    >
                        <Typography fontSize={24} color={'GrayText'}>Adicionando contacto</Typography>
                        <Typography onClick={setOpenAndClose} sx={{':hover': {color: 'red'}, cursor: 'pointer'}} fontSize={14} color={'GrayText'}><Icon>close</Icon></Typography>
                    </Box> */}

                    <Box flex={1} overflow={'auto'}>
                        {user && <Box display={'flex'} gap={1} > 
                            <Box width={'50%'}>
                                <Input  value={user.full_name} disabled/>
                            </Box>

                            <Box width={'50%'}>
                                <Input  value={user.number_identity} disabled/>
                            </Box>
                        </Box> }
                        
                        <Box /* component={'form'} noValidate onSubmit={handleSubmit} */>
                            {contacts && contacts.map((contact, id) => (
                                <Box key={id}>
                                {id !== 0 && <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography  fontSize={14} color={'GrayText'}>{id + 1}º Contacto</Typography>
                                    <Typography display={'inline-block'} onClick={() => {removeOne(id)}} sx={{':hover': {color: 'red'}, cursor: 'pointer'}} fontSize={14} color={'GrayText'}><Icon>remove</Icon></Typography>
                                </Box>
                                }
                                    <Box>
                                        {!contactsType[id].is_email ? <Box>
                                            <InputLabel sx={{mt: 1, mb: .6}} className='label'>Número de telefone</InputLabel>
                                            <Box display={'flex'} alignItems={'center'}>
                                                    <Box>
                                                        <Autocomplete
                                                            id="country-select-demo"
                                                            options={countries}
                                                            autoHighlight
                                                            value={countrySelected[id]??null}
                                                            isOptionEqualToValue={(option) => option.phone}
                                                            onInputChange={(_, newValue) => countryChange(newValue, id)}
                                                            getOptionLabel={(option) => option.phone}
                                                            renderOption={(props, option) => {
                                                            const { key, ...optionProps } = props;
                                                            return (
                                                                <Box
                                                                key={option.label}
                                                                component="li"
                                                                sx={{ '& > img': { mr: .5, flexShrink: 0 } }}
                                                                {...optionProps}
                                                                >
                                                                <img
                                                                    loading="lazy"
                                                                    width="16"
                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                    alt=""
                                                                />
                                                                <PnoWrap fontSize={10} color='black' text={option.label}/>
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="País"
                                                            inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                                                                        )}
                                                                                                      />
                                                    </Box>
                                                  
                                                    <Box flex={1}>
                                                        <TextField
                                                            fullWidth
                                                            placeholder="nº de telefone"
                                                            value={contact.contact}
                                                            type='number'
                                                            name="contact"
                                                            onChange={(e) => handleChange(id, e)}                                            
                                                        />
                                                    </Box>
                                                    {(contact.country_id && contact.contact) && <Box sx={{ display: 'none'}}>
                                                        <TextField
                                                            fullWidth
                                                            placeholder="nº de telefone"
                                                            value={`(+${contact.country_id}) ${contact.contact}`}
                                                            name="full_phone"                                           
                                                        />
                                                    </Box>}
                                            </Box>
                                        </Box>:
                                        <Box>
                                            <InputLabel sx={{mt: 1, mb: .6}}>Email</InputLabel>
                                           
                                            <Input
                                                type={'email'}
                                                label={'Email'}
                                                value={contact.contact}
                                                name="contact"
                                                onChange={(e) => handleChange(id, e)}    
                                            />
                                        </Box>}
                                    </Box>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                           <Typography fontSize={13}>Contacto Principal</Typography>
                                          
                                            <Checkbox checked={contactsType[id].is_main??false} name='is_main' onChange={(e) => handleChangeCheck(id, e)}/>
                                        </Box>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                           <Typography fontSize={13}>Dono do contacto</Typography>
                                           
                                                <Checkbox checked={contactsType[id].is_owner??false} name='is_owner' onChange={(e) => handleChangeCheck(id, e)} />
                                        </Box>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                           <Typography fontSize={13}>Telefone de casa</Typography>
                                          
                                                <Checkbox checked={contactsType[id].is_home_phone??false} name='is_home_phone' onChange={(e) => handleChangeCheck(id, e)} />
                                        </Box>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                           <Typography fontSize={13}>É Email</Typography>
                                           
                                                <Checkbox checked={contactsType[id].is_email??false} name='is_email' onChange={(e) => handleChangeCheck(id, e)} />
                                        </Box>
                                    </Box>
                                </Box>))
                            }
                        </Box>

                    </Box>

                    <Box display={'flex'} gap={1}>
                        
                        <ButtonIndicator 
                            onClick={addNewContact}
                            text='Adicionar outro contacto' 
                            variant="contained" 
                            color='primary'
                            endIcon={<Icon>add</Icon>} 
                        />
                    </Box>
                </Box>
        </Box>
    </Box>
  )
}
