import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { PnoWrap } from '../paragraphy'

export function ButtonIndicator({text = undefined, textColor = "white", isLoading = false, indicatorColor = 'secondary', ...rest}) {
  return (
    <Button
    {...rest}
    >
      {
        !isLoading ? <PnoWrap color={textColor} text={text??undefined}/> 
        : <CircularProgress color={indicatorColor} size={20}/> }
    </Button>
  )
}
