import { Box, Paper } from '@mui/material';
import React from 'react';
import { PnoWrap } from '../paragraphy';

export function CardDash({ account = '', description = '', colorD = undefined, ...rest }) {
  return (
    <Box component={Paper} p={2} minWidth={235} height={110} {...rest}>
      <div style={{ marginBottom: '1rem', marginTop: '.5rem' }}>
        <PnoWrap fontSize={20} color={colorD ?? '#222'} text={description} />
        <h1 className='title'>{account}</h1>
      </div>
    </Box>
  )
}
