import React from 'react'

const Head = ({ title }) => {
    React.useEffect(() => {
        document.title = `${title} | NTNL Software`;
    }, [title]);

    return (<></>);
}

export { Head }
