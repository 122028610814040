import { API } from "./api"
import { api_services } from "./config"

export const GET_LEVEL = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/academic_levels?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
    }
}

const POST_LEVEL = async (data) => {

    try {
        const result = await api_services('/academic_levels', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const updateLevels = async (id, data) => {
    try {
        const result = await api_services(`/academic_levels/${id}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getOne = async (id) => {
    try {
        const result = await api_services(`/academic_levels/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/academic_levels/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async () => {
    try {
        const result = await api_services(`/academic_levels/restore_all`, {
            method: 'GET',
        })
        console.log(result);
        
        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreOneDeleted = async (id) => {
    try {
        const result = await api_services(`/academic_levels/restore_one/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
const DeleteOne = async (id) => {    
    try {
        const result = await api_services(`/academic_levels/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const LevelService = {
    POST_LEVEL,
    getOne,
    DeleteOne,
    updateLevels,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted
}