import React, { useState } from 'react'
import styles from './SeeMoreFunctionary.module.css'
import Chart from "react-apexcharts";

import { Avatar, Box, useTheme } from '@mui/material'
import { Head } from '../../shared'
import { MenuDrawer, PnoWrap } from '../../components'
import ImageAvatar from '../../assets/images/avatar/2.jpg'

export function StudentsPerformance() {
    const { palette } = useTheme();
    const [series, setSeries] = useState([{
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ])


    return (
        <MenuDrawer>
            <Head title='Datalhes' />
            <section sx={{ m: 2 }}>
                <Box display={'flex'}  >
                    <Box className={styles.oneBloco} flex={1} mx={0} ml={2} >
                        <div className={styles.bg}></div>
                        <Avatar src={ImageAvatar} alt='imagem-de-perfil' className={styles.avatar} />
                        <div className={styles.discriptioBloco}>
                            <p>Nome: Madrugada de Carvalho</p>
                            <p>Email: madrugadaoca@gmail.com</p>
                            <div>
                                <p>Telefone:</p>
                                <p>(+244) 999 999 999</p>
                            </div>
                        </div>
                    </Box>
                    <Box className={styles.oneBloco}  minWidth={300} display={'flex'} flexDirection={'column'}justifyContent={'center'} bgcolor={palette.primary.main}> {/*
                    <div className={styles.bg}></div> 
                    <Avatar src={ImageAvatar} alt='imagem-de-perfil' className={styles.avatar} /> */}
                        <Box p={2}>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Nº de estudante: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={'00722018'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Turma: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={' LEI2M'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Periodo: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={' Manhã'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Classe Actual: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={'11º'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Sala: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={' 11'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Ano lectivo actual: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={' 2024'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Curso: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={'Informática'}  />
                            </Box>
                            <Box display={'flex'} gap={1}>
                                <PnoWrap fontSize={14} text={'Departamento: '} fontWeight="bold"  />
                                <PnoWrap fontSize={14} text={'Tecnologoa'}  />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <article className={styles.twoBloco}>
                    <div className={styles.table}>
                        <h1 className='title'>Desempenho</h1>
                        <Box >
                        <Chart
                            options={{
                                chart: {
                                    id: "basic-bar"
                                },
                                xaxis: {
                                    categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                                    
                                }
                                
                            }}
                            series={series}
                            type="area"
                        />    
                        </Box>
                    </div>
                    <div className={styles.skills}>
                        <h1 className='title'>Adicionais</h1>
                        <ul>
                            <li>Graduado</li>
                            <li>Pós-Graduado</li>
                            <li>MBA</li>
                        </ul>
                    </div>
                </article>
            </section>
        </MenuDrawer>
    )
}
