import styles from './Dashboard.module.css';
import * as React from 'react';
import { MenuDrawer } from '../components/Dashboard/MenuDrawer';
import { Box, Grid, Paper, useTheme } from '@mui/material';
import { Head } from '../shared';
import { CardDash } from '../components/cards/CardDash';
import Chart from 'react-apexcharts'

export function Dashboard() {
  const { palette } = useTheme()
  const [dataCharts, setDataCharts] = React.useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [2024, 2025, 2026, 2027, 2028, 2029, 2030]
      },
      colors: [palette.primary.main, palette.secondary.main],
    },
    series: [
      {
        name: "Escolas",
        data: [20, 30, 45, 50, 60, 70, 87]
      }, {
        name: "Professores",
        data: [60, 75, 87, 99, 110, 150, 170]
      },
      
    ]
  });

  const [dataChart, setDataChart] = React.useState({
    options: {
      labels: ["Alunos", "Funcionários"],
      colors: [palette.primary.main, palette.secondary.main],
    },
    series: [75000, 25000] // Total dos dados finais para "Escolas" e "Professores"
  });

  return (
    <MenuDrawer page='Dashboard'>
      <Head title='Painel de Controle' />
      <Box m={2}>
        <Grid container spacing={2} my={2}>
          <Grid item xs={12} sm={12} md={3}>
            <CardDash account='Escolas' description='20' />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CardDash account='Professores' description='120' />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CardDash account='Alunos' description='+ 75k' />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CardDash account='Funcionários' description='+ 25k' />
          </Grid>
        </Grid>

        <sectnion className={styles.bockChart}>
          <article className={styles.articleGrafico} style={{ marginRight: '1rem' }}>
            <Chart options={dataCharts.options} series={dataCharts.series} type="bar" />
          </article>
          <article className={`${styles.articleGrafico} ${styles.pie}`}>
            <Chart options={dataChart.options} series={dataChart.series} type="pie" />
          </article>
        </sectnion>
      </Box>
    </MenuDrawer>
  );
}