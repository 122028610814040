export const RoutesList = [
    {
        title: 'Pagina Inicial',
        to: '/',
        mainIcon: 'fas fa-house',
        subMenu: undefined
    },
    {
        title: 'Gestão de Usuários',
        to: undefined,
        mainIcon: 'fas fa-users-cog',
        subMenu: [
            // {
            //     title: 'Inscrições',
            //     Icon: 'fas fa-user-tie',
            //     to: '/people/students/inscription'
            // },
            {
                title: 'Alunos',
                Icon: 'fas fa-user-graduate',
                to: '/people/students'
            },
            {
                title: 'Cand. Inscritos',
                Icon: 'fas fa-users',
                to: '/people/students/enrolled'
            },
            {
                title: 'Cand. Selecionados',
                Icon: 'fas fa-users',
                to: '/people/students/selected'
            },
            {
                title: 'Funcionários',
                Icon: 'fas fa-user-tie',
                to: '/people/functionary'
            },
            // {
            //     title: 'Resultado',
            //     Icon: 'fas fa-user-tie',
            //     to: '/people/response'
            // },
            {
                title: 'Perfis de Acesso',
                Icon: 'fas fa-user-gear',
                to: '/people/access'
            }
        ]
    },
    {
        title: 'Gestão Académica',
        to: undefined,
        mainIcon: 'fas fa-school',
        subMenu: [
            {
                title: 'Escolas',
                Icon: 'fas fa-school',
                to: '/academic/school'
            },
            {
                title: 'Cursos',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Salas',
                Icon: 'fas fa-chalkboard-teacher',
                to: '/academic/classes'
            },
            {
                title: 'Disciplinas',
                Icon: 'fas fa-book',
                to: '/academic/discipline'
            },
            {
                title: 'Matriculas',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Configuração de Exame',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Confirmação de Matricula',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Desempenho dos Alunos',
                Icon: 'fa-solid fa-chart-simple',
                to: '/academic/performance'
            },
            {
                title: 'Grade Curricular',
                Icon: 'fa-solid fa-book-open',
                to: ''
            },
            {
                title: 'Histórico do Aluno',
                Icon: 'fa-solid fa-book-open',
                to: ''
            },
            {
                title: 'Niveis Academicos',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/levels'
            },
            {
                title: 'Localizações',
                Icon: 'fa-solid fa-map',
                to: '/academic/location'
            },
            {
                title: 'Pauta',
                Icon: 'fas fa-chalkboard-teacher',
                to: '/academic/classes'
            },
            {
                title: 'Transferências',
                Icon: 'fas fa-elevator',
                to: '/academic/classes'
            },
        ]
    },
    {
        title: 'Gestão Frequência',
        to: undefined,
        mainIcon: 'fas fa-person-circle-check',
        subMenu: [
            {
                title: 'Presenças',
                Icon: 'fas fa-check',
                to: '/academic/school'
            },
            {
                title: 'Faltas',
                Icon: 'fas fa-remove',
                to: '/academic/school'
            },
        ]
    },
    {
        title: 'Gestão Financeira',
        to: undefined,
        mainIcon: 'fas fa-wallet',
        subMenu: [
            {
                title: 'Cartão',
                Icon: 'fas fa-id-card',
                to: ''
            },
            {
                title: 'Declaração de Frequência',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Declaração com Nota',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Exame Especial',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Exame Recurso',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Folha de Prova',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Transferência',
                Icon: 'fas fa-elevator',
                to: ''
            },
            {
                title: 'Certificado',
                Icon: 'fas fa-ranking-star',
                to: ''
            },
            {
                title: 'Uniforme',
                Icon: 'fas fa-shirt',
                to: ''
            },
            {
                title: 'Justificativo de Faltas',
                Icon: 'fas fa-newspaper',
                to: ''
            },
            {
                title: 'Energia e Água',
                Icon: 'fa-solid fa-building',
                to: ''
            }
        ]
    },
    {
        title: 'Relatórios/Dados Estatísticos',
        to: undefined,
        mainIcon: 'fas fa-chart-bar',
        subMenu: [
            {
                title: 'Alunos Matriculados',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Cursos',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            },
            {
                title: 'Professores',
                Icon: 'fas fa-user-tie',
                to: ''
            },
            {
                title: 'Salas',
                Icon: 'fas fa-chalkboard-teacher',
                to: ''
            },
            {
                title: 'Aproveitamento*',
                Icon: 'fas fa-clipboard',
                to: ''
            }
        ]
    },
    {
        title: 'Comunicação',
        to: undefined,
        mainIcon: 'fa-solid fa-comment',
        subMenu: [
            {
                title: 'Alunos',
                Icon: 'help',
                to: ''
            },
            {
                title: 'Coordenação',
                Icon: 'help',
                to: ''
            },
            {
                title: 'Correio Interno',
                Icon: 'fa fa-paper-plane',
                to: ''
            },
            {
                title: 'Direcção',
                Icon: 'store',
                to: ''
            },
            {
                title: 'Instituições de Ensino',
                Icon: 'store',
                to: ''
            },
            {
                title: 'Notificações',
                Icon: 'help',
                to: 'fa fa-bell'
            },
            {
                title: 'Professores',
                Icon: 'store',
                to: ''
            },
            {
                title: 'Pais e Encarregados',
                Icon: 'fa fa-world',
                to: ''
            }
        ]
    },
    {
        title: 'Segurança e Privacidade',
        to: undefined,
        mainIcon: 'fas fa-lock',
        subMenu: [
            {
                title: 'Abertura e Fecho do Sistema',
                Icon: 'key',
                to: '/box'
            },
            {
                title: 'Relatório de Acesso',
                Icon: 'store',
                to: ''
            },
            {
                title: 'Alterar Senha',
                Icon: 'assignment',
                to: ''
            }
        ]
    },
    {
        title: 'Configurações do Sistema',
        to: undefined,
        mainIcon: 'fa-solid fa-gear',
        subMenu: [
            {
                title: 'Calendário Escolar',
                Icon: 'fas fa-calendar',
                to: ''
            },
            {
                title: 'Decreto',
                Icon: 'fas fa-calendar',
                to: ''
            },
            {
                title: 'Horario',
                Icon: 'fas fa-clock',
                to: ''
            },
            {
                title: 'Regras Interna da Escola',
                Icon: 'fas fa-rul',
                to: ''
            },
        ]
    },
    {
        title: 'Suporte Técnico',
        to: undefined,
        mainIcon: 'fas fa-headset',
        subMenu: [
            {
                title: 'Suporte',
                Icon: 'help',
                to: ''
            }
        ]
    },
]