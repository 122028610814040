import React, { useMemo, useState } from 'react'
import { MenuDrawer, SuccessResponse } from '../../components'
import {
    Box, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Environment, Head } from '../../shared'
import { classroomService, GET_CLASSROOMS } from '../../shared/services/class';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

export function Classes() {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { palette } = useTheme();

    const { token } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams()
    const [totalAccount, setTotalAccount] = React.useState(0);
    const navigate = useNavigate();
    const search = useMemo(() => searchParams.get('search') || '', [searchParams])
  
    const page = useMemo(() => searchParams.get('page') || 1, [searchParams])
    const [MsgSuccess, setMsgSuccess] = useState(undefined);
    const [Change, setChange] = React.useState(false);

    React.useEffect(() => {
        const findAllClasses = async () => {
            try {
                setLoading(true);
                setError(null);
                const { url, options } = GET_CLASSROOMS(page, Environment.ROW_LIMIT, search, token);
                const response = await fetch(url, options);
                const result = await response.json();
                
                setData(result.data);
                setTotalAccount(result.meta.total)
            } catch (error) {
                setError(error.message)
            } finally {
                setLoading(false);
            }
        }
        findAllClasses();
        setChange(false)
    }, [search, page, Change]);

    const DeleteClassrooms = async (id) => {
        setLoading(true)
        const response = await classroomService.DeleteOne(id);
        if (response instanceof Error){
            setError(response.message);
            toast.error(response.message)
            setLoading(false);
      
          } else {
      
              toast.success('Turma eliminada com sucesso.', {
              position: 'top-right',
              className: 'custom-toast',
            })
            setChange(true)
            setLoading(false);
          }
      }

    return (
        <MenuDrawer page='Turmas'>
            <Head title='Turmas' />
            {!!MsgSuccess && <SuccessResponse title={MsgSuccess} setClose={setMsgSuccess} urlNext='/academic/discipline' />} 
            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <Box m={1} >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
                            <Grid item>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Pesquisar"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                        onChange={(evt) => { setSearchParams({search: evt.target.value}, { replace: true})}}
                                        value={search??''}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Link to='/academic/classes/register' className='btn-success'>
                                    Cadastrar Turma <i className='fas fa-plus'></i>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link size='large' color='error' to={'/academic/classes/erased'} className='btn-error'>
                                Ir para lixeira <i className='fas fa-trash'></i>
                                </Link>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'>Nome</TableCell>
                                            <TableCell className='title-table text-center'>Curso</TableCell>
                                            <TableCell className='title-table text-center'>Descrição</TableCell>
                                            <TableCell className='title-table text-center'>Coordenador</TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.length > 0 ?
                                            data.map((item) => (
                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className='text-center'>{item.name}</TableCell>
                                                    <TableCell className='text-justify'>{item.course_id}</TableCell>
                                                    <TableCell className='text-justify'>{item.description}</TableCell>
                                                    <TableCell className='text-center'>
                                                        {item.user.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                            <Link to={`/academic/classes/update/${item.id}`}>
                                                                <Typography className='btn-icon btn-edit'>
                                                                    <i className="fa-solid fa-edit"></i>
                                                                </Typography>
                                                            </Link>
                                                            <Typography className='btn-icon btn-edit' onClick={() => DeleteClassrooms(item.id)}>
                                                                <i className="fa-solid fa-trash"></i>
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )) : (
                                                <TableRow>
                                                    <TableCell colSpan={12} align="center">
                                                        <Typography width={250}>Lista de Turmas vazia</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                        {error && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <Typography width={250}>{error}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <LinearProgress color='secondary' />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                    page={Number(page)} onChange={(_, newPage) => setSearchParams({_, page: newPage.toString() }, { replace: true })}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}
