import React, { useState } from 'react'
import {Navbar} from './components/NAvbar'
import { Carousel } from './components/Carossel'
import { QuestionNational } from './components/QuestionNational';

export const Inscription = () => {
  const [openQuenstionModal, setOpenQuestionModal] = useState(false);
  const handleOpenClose = () => {
    setOpenQuestionModal(!openQuenstionModal)
  }
  return (
    <main className='page-container'>
      <Navbar />
      <Carousel onClick={handleOpenClose}/>
      {openQuenstionModal && <QuestionNational onClick={handleOpenClose}/>}
    </main>
  )
}
