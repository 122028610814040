import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
// import MenuIcon from "@material-ui/icons/Menu";
// import Button from "@material-ui/core/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    AppBar,
    Toolbar,
    Button,
    Icon,
    Typography,
    useTheme,
    Box,
} from "@mui/material";
import {styled} from '@mui/material/styles'
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

// Using Inline Styling
// const useStyles = styled((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//     },
// }));

const Root = styled('div')(({ theme }) => ({
    flexGrow: 1,
  }));
  
  // Estilizando o botão
  const MenuButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
  }));

// Exporting Default Navbar to the App.js File
export const Navbar = () => {
    const {palette } = useTheme()
    const small = useMediaQuery("(max-width:600px)");
    const full = useMediaQuery("(min-width:600px)");

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className={Root}>
            <AppBar position="static">
                <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    {small && (
                        <>
                            <List>
                                <ListItem>
                                    <Button
                                        onClick={
                                            handleClick
                                        }
                                        color={'secondary'}
                                    >
                                        <Icon color={palette.secondary.main}>menu</Icon>
                                        {open ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </Button>
                                    <Typography
                                        variant="h6"
                                        color="inherit"
                                        onClick={() => {
                                            console.log(
                                                "logo clicked"
                                            );
                                            setOpen(false);
                                        }}
                                    >
                                        
                                        <Typography color='#333' fontWeight={'bold'}><i className="fa-solid fa-layer-group" color='#333'></i> NTNL Software</Typography>
                                    </Typography>
                                </ListItem>
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                    >
                                        <ListItem >
                                            <ListItemText primary="Inicio" />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemText primary="Informações gerais" />
                                        </ListItem>{" "}
                                        <ListItem >
                                            <ListItemText primary="Calendário" />
                                        </ListItem>
                                        <ListItem >
                                            <ListItemText primary="Topicos" />
                                        </ListItem>
                                    </List>
                                        <Link to={'/login'}>
                                            <Button color="inherit">
                                                Entrar
                                            </Button>
                                        </Link>
                                </Collapse>
                            </List>
                        </>
                    )}

                    {full && (
                        <>
                            <Box display={'flex'} alignItems={'center'}>
                                    <Typography
                                        variant="h6"
                                        color="inherit"
                                    >
                            
                                        <Typography color='#333' fontWeight={'bold'}><i className="fa-solid fa-layer-group" color='#333'></i> NTNL Software</Typography>
                                    </Typography>
                                    <Button color="inherit">
                                        Inicio
                                    </Button>
                                    <Button color="inherit">
                                        Informações gerais
                                    </Button>
                                    <Button color="inherit">
                                        Calendário
                                    </Button>
                                    <Button color="inherit">
                                        Topicos
                                    </Button>
                            </Box>
                            <Link to={'/login'}>
                                <Button color="inherit">
                                    Entrar
                                </Button>
                            </Link>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}