import { Box, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Input, MenuDrawer } from "../../components";
import { Environment, Head } from "../../shared";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export function Access() {
  const data = [];
  const { palette } = useTheme()

  return (

    <MenuDrawer page='Controle de acesso'>
      <Head title='Cadastra Perfil de acesso' />
      <Box component={Paper} p={2} m={2} flex={1}>
        <Box mt={1} mb={3} px={1}>
          <h1 className="title">Cadastra Perfil de acesso</h1>
          <p>Preencha todos os campos para Registrar novo perfil de acesso</p>
        </Box>
        <Box >
          <Box display={'flex'} gap={1} mb={2} mx={1}>
            <Box width={'100%'}>
              <Box display={'flex'} gap={1}>
                <Typography fontSize={14}>Titulo do perfil</Typography>
                <Typography fontSize={14} color={'error'}>*</Typography>
              </Box>
              <Box>
                <Input fullWidth placeholder='Ex: Admin' />
              </Box>
            </Box>
            <Box width={'100%'}>
              <Box display={'flex'} gap={1}>
                <Typography fontSize={14}>Status</Typography>
                <Typography fontSize={14} color={'error'}>*</Typography>
              </Box>
              <Box>
                <Input fullWidth placeholder='Activo ' />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box >
          <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
              <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                  <TableRow>
                    <TableCell className='title-table text-center'>Perfis</TableCell>
                    <TableCell className='title-table text-center'>Criar</TableCell>
                    <TableCell className='title-table text-center'>Visualizar</TableCell>
                    <TableCell className='title-table text-center'>Editar</TableCell>
                    <TableCell className='title-table text-center'>Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[1, 3, 5].length > 0 &&
                    [1, 3, 7].map((item, id) => (
                      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell className='text-center'>Professor</TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                      </TableRow>
                    ))
                  }

                  {false && (
                    <TableRow>
                      <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                    </TableRow>)
                  }

                </TableBody>
                <TableFooter>
                  {data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                      <Pagination
                        count={Math.ceil(1 / Environment.ROW_LIMIT)}
                        page={Number(1)} /* onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })} */
                        color='primary'
                        size='small'
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>

    </MenuDrawer>
  )
}
