import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import {
    Autocomplete, Box, Button, Container, Drawer,
    FormControl, FormControlLabel, FormLabel, IconButton,
    InputLabel, MenuItem, Paper, Radio, RadioGroup, Select,
    TextField, Typography, useTheme
} from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Input } from '../form';
import { PnoWrap } from '../paragraphy'
import { countryServices, functionaryServices, Head } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { AddAndUpdateContact } from '../Modals';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(100%)',
    height: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 2,
});

const schema = yup.object().shape({
    photo: yup.string().optional(),
    full_name: yup.string().required('Campo obrigatorio').min(6, 'Nome Completo deve ter no minimo 6 caracteres'),
    birth_date: yup.string().required('Campo obrigatorio'),
    gender: yup.string().oneOf(['M', 'F']).required('Campo obrigatorio'),
    location: yup.string().required('Campo obrigatorio').min(4, 'Localição deve ter no minimo 4 caracteres'),
    civil_status_id: yup.string().required('Campo obrigatorio'),
    profession: yup.string().required('Campo obrigatorio').min(4, 'Profissão deve ter no minimo 4 caracteres'),
    country_id: yup.string().optional(),
    province_id: yup.string().required('Campo obrigatorio'),
    is_student: yup.string().oneOf(['SIM', 'NÃO']).optional().default('NÃO'),
    number_identity: yup.string().required('Campo obrigatorio'),
    academic_formation: yup.string().required('Campo obrigatorio'),
})

export function AddAndUpdateUser() {
    const params = useParams()
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();

    const { palette } = useTheme();
    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            photo: undefined,
            full_name: "",
            birth_date: "",
            gender: "",
            location: "",
            civil_status_id: undefined,
            profession: '',
            country_id: undefined,
            province_id: undefined,
            is_employee: true,
            is_student: '',
            number_identity: "",
            academic_formation: "",
        }
    })

    const [functionaryUpdate, setFunctionaryUpdate] = useState(null);
    const [countrySelected, setCountrySelected] = useState(null);

    const [civilState, setCivilState] = useState([
        {
            id: '1',
            name: 'Solteiro',
            status: false,
            sort_order: false
        },
        {
            id: '2',
            name: 'Casado',
            status: false,
            sort_order: false
        },
        {
            id: '3',
            name: 'Divorciado',
            status: false,
            sort_order: false
        }
    ]);

    const [icons, setIcons] = useState(null);
    useEffect(() => {
        countryServices.getAll().then((data) => {

            setCountries(data)
        }).catch(err => {
            console.log({ err });
        })
    }, [])

    useEffect(() => {
        if (params && params.id && typeof Number(params.id) === 'number') {
            setIsUpdate(true)
            functionaryServices.getOne(params.id).then((data) => {
                setFunctionaryUpdate(data)
            }).catch(err => {
                console.log({ err });
            })
        }
    }, [params, isUpdate])


    useEffect(() => {
        if (functionaryUpdate) {
            setValue('full_name', functionaryUpdate.full_name);
            setValue('birth_date', functionaryUpdate.birth_date);
            setValue('gender', functionaryUpdate.gender);
            setValue('number_identity', functionaryUpdate.number_identity);
            setValue('location', functionaryUpdate.location);
            setValue('academic_formation', functionaryUpdate.academic_formation);
            setValue('civil_status_id', functionaryUpdate.civil_status_id);
            setValue('profession', functionaryUpdate.profession);
            setValue('country_id', functionaryUpdate.country_id);
            setValue('is_employee', functionaryUpdate.is_employee);
            setValue('is_student', functionaryUpdate.is_student);

            let aux = countries[countries.findIndex((v) => {
                if (v.phone === functionaryUpdate.country_id?.toString()) {
                    return v
                }
            })]
            setCountrySelected(aux)
        }

    }, [functionaryUpdate, countrySelected, /* isUpdate */]);


    const countryChange = (value) => {
        let aux = countries[countries.findIndex((v) => {
            if (v.label === value?.toString()) {
                return v
            }
        })]
        setCountrySelected(aux)
        setValue('country_id', countrySelected?.phone)

    }

    const submitData = async (data) => {
        try {
            const response = await functionaryServices.post({ ...data, photo: icons?.name ?? '' });
            navigate('/people/functionary')
            //  alert('user salvo com sucesso!!')
            // editContact({id: response.id, isUpdate: false, countries})
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <MenuDrawer page={isUpdate ? 'Funcionários | actualização' : 'Funcionários | cadastros'}>
            <Head title='Funcionario | add' />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : <h1 mr={2} color={palette.success.main} fontWeight={'bold'} fontSize={20}>
                                Actualização
                            </h1>
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(submitData) : handleSubmit(submitData)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Nome Completo</InputLabel>
                                    <Controller
                                        control={control}
                                        name='full_name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                disabled={!!isUpdate}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.full_name}
                                                helperText={errors.full_name ? errors.full_name.message : ''}

                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Número de identidade</InputLabel>

                                    <Controller
                                        control={control}
                                        name='number_identity'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} value={value ?? ''}
                                                placeholder={'Digite o nº de B.I ou CEP'}
                                                color='primary'
                                                error={!!errors.number_identity}
                                                helperText={errors.number_identity ? errors.number_identity.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Data de nascimento</InputLabel>
                                    <Controller
                                        control={control}
                                        name='birth_date'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ? value.toString() : ''}
                                                type={'date'} color='primary'

                                                error={!!errors.birth_date}
                                                helperText={errors.birth_date ? errors.birth_date.message : ''} />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>Estado cívil</InputLabel>
                                    <Controller
                                        control={control}
                                        name='civil_status_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    margin="normal"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.civil_status_id}
                                                    onChange={onChange}
                                                >
                                                    {civilState.length > 0 && civilState.map((el, id) => (
                                                        <MenuItem key={id} value={el.id}><PnoWrap color='GrayText' text={el.name} /></MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.civil_status_id && <PnoWrap color='error' fontSize={12} mt={.4} text={'estado cívil é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Profissão</InputLabel>
                                    <Controller
                                        control={control}
                                        name='profession'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} value={value ?? ''}
                                                placeholder="Digite sua Profissão"
                                                color='primary'
                                                error={!!errors.profession}
                                                helperText={errors.profession ? errors.profession.message : ''} />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Formação acadêmica</InputLabel>
                                    <Controller
                                        control={control}
                                        name='academic_formation'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} value={value ?? ''}
                                                placeholder="Digite sua foração academica"
                                                color='primary'
                                                error={!!errors.academic_formation}
                                                helperText={errors.academic_formation ? errors.academic_formation.message : ''} />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1, mb: 2 }}>País</InputLabel>
                                    <Controller
                                        control={control}
                                        name='country_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                id="country-select-demo"
                                                options={countries}
                                                fullWidth
                                                autoHighlight
                                                value={countrySelected ?? null}
                                                isOptionEqualToValue={(option) => option.phone}
                                                onInputChange={(_, newValue) => countryChange(newValue)}
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(props, option) => {
                                                    const { key, ...optionProps } = props;
                                                    return (
                                                        <Box
                                                            key={key}
                                                            component="li"
                                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                            {...optionProps}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {option.label} ({option.code}) +{option.phone}
                                                        </Box>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Selecione"
                                                        error={!!errors.country_id}
                                                        onChange={onChange}
                                                        helperText={errors.country_id ? errors.country_id.message : ''}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />
                                                )}
                                            />)}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Provincia</InputLabel>
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite a provincia'}
                                                color='primary'
                                                error={!!errors.province_id}
                                                helperText={errors.province_id ? errors.province_id.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Endereço</InputLabel>
                                    <Controller
                                        control={control}
                                        name='location'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite sua localização'}

                                                error={!!errors.location}
                                                helperText={errors.location ? errors.location.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label' sx={{ mt: 1, mb: 2 }}>Selecione o icon </InputLabel>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        fullWidth
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ mb: 2, py: 2 }}
                                    >
                                        Carregar Imagem
                                        <VisuallyHiddenInput name='icons' type='file'
                                            onChange={(e) => setIcons(e.target.files)} />
                                    </Button>
                                </div>
                            </div>

                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='gender'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl>
                                                <FormLabel className='label' id="demo-controlled-radio-buttons-group">
                                                    Gênero
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                                                </RadioGroup>
                                                {errors.gender && <PnoWrap color='error' fontSize={12} mt={.4} text={'genero é Óbrigatório'} />}
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='is_student'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl>
                                                <FormLabel className='lBEL' id="demo-controlled-radio-buttons-group">
                                                    É aluno desta instituição
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel value={'SIM'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel value={'NAO'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                            </Box>

                            <AddAndUpdateContact countries={countries} user={isUpdate ? functionaryUpdate : null} />

                            <Box>

                            </Box>
                            <Box>
                                {isUpdate ?
                                    <Box sx={{ display: 'flex', my: 2 }}>
                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2, mr: 1 }}
                                            color='primary'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit()}
                                        >
                                            Actualizar
                                        </Button>

                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2 }}
                                            color='secondary'
                                            fullWidth
                                        // onClick={() => editContact({id: functionaryUpdate.id, isUpdate: true, countries})}
                                        >
                                            Actualizar contacto
                                        </Button>
                                    </Box>
                                    : <Box sx={{ my: 2 }}>
                                        <Button
                                            sx={{ py: 2 }}
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitData)}
                                        >
                                            Guardar
                                        </Button>
                                    </Box>}


                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}