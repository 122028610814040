import { api_services } from './config';


const login = async (data) => {
    console.log(data);
    
    try {
        const result = await api_services('/auth/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        console.log({result});
        
        return result.json()
    } catch (error) {
        console.log('Erro ao logar', error);        
    }
}

const loginRegister = async ({name, email, password}) => {
    try {
        const result = await api_services('/auth/register', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            data: JSON.stringify({email, password})
        })
        
    } catch (error) {
        console.log('Erro ao registrar sessão', error);        
    }
}

export const sessionServices = {
    login,
    loginRegister
}