import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Box, Button, Container, InputLabel, Paper } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from '../form';
import { functionaryServices, Head } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(100%)',
    height: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 2,
});

const schema = yup.object().shape({
    
})

export function AddAndUpdateEsola() {
    const [isUpdate, setIsUpdate] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            
        }
    })

    const [functionaryUpdate, setFunctionaryUpdate] = useState(null);
    const [countrySelected, setCountrySelected] = useState(null);

    const [data, setData] = React.useState([]);
    const [icons, setIcons] = useState(null);
    

    useEffect(() => {
        if (functionaryUpdate) {
            setValue('full_name', functionaryUpdate.full_name);
            setValue('birth_date', functionaryUpdate.birth_date);
            setValue('gender', functionaryUpdate.gender);
            setValue('email', functionaryUpdate.email);
            setValue('location', functionaryUpdate.location);
            setValue('academic_formation', functionaryUpdate.academic_formation);
            setValue('civil_status_id', functionaryUpdate.civil_status_id);
            setValue('profession', functionaryUpdate.profession);
            setValue('country_id', functionaryUpdate.country_id);
            setValue('is_employee', functionaryUpdate.is_employee);
            setValue('is_student', functionaryUpdate.is_student);

            let aux = countries[countries.findIndex((v) => {
                if (v.phone === functionaryUpdate.country_id?.toString()) {
                    return v
                }
            })]
            setCountrySelected(aux)
        }

    }, [functionaryUpdate, countrySelected, /* isUpdate */]);

    const submitData = async (data) => {
        try {
            const response = await functionaryServices.post({ ...data, photo: icons?.name ?? '' });
            navigate('/people/functionary')
            //  alert('user salvo com sucesso!!')
            // editContact({id: response.id, isUpdate: false, countries})
        } catch (error) {
            console.error(error)
        }
    }



    return (
        <MenuDrawer page='Esolas'>
            <Head title={id ? 'Actualizar dados do Disciplina' : 'Cadastra Escola'} />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!id
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(submitData) : handleSubmit(submitData)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Nome</InputLabel>
                                    <Controller
                                        control={control}
                                        name='full_name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={data.nome}
                                                disabled={!!isUpdate}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.full_name}
                                                helperText={errors.full_name ? errors.full_name.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Director</InputLabel>
                                    <Controller
                                        control={control}
                                        name='director'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} 
                                                value={data.diretor}
                                                placeholder={'Digite o nome do Director'}
                                                color='primary'
                                                error={!!errors.director}
                                                helperText={errors.director ? errors.director.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Localização</InputLabel>
                                    <Controller
                                        control={control}
                                        name='location'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange} 
                                                value={data.localizacao}
                                                placeholder={'Digite a localização'}
                                                color='primary'
                                                error={!!errors.location}
                                                helperText={errors.location ? errors.location.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Provincia</InputLabel>
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite a provincia'}
                                                color='primary'
                                                error={!!errors.province_id}
                                                helperText={errors.province_id ? errors.province_id.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Endereço</InputLabel>
                                    <Controller
                                        control={control}
                                        name='location'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite sua localização'}
                                                error={!!errors.location}
                                                helperText={errors.location ? errors.location.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label' sx={{ mt: 1, mb: 2 }}>Logotipo</InputLabel>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        fullWidth
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ mb: 2, py: 2 }}
                                    >
                                        Carregar Imagem
                                        <VisuallyHiddenInput name='icons' type='file'
                                            onChange={(e) => setIcons(e.target.files)} />
                                    </Button>
                                </div>
                            </div>

                            <Box>
                                {id ?
                                    <Box sx={{ display: 'flex', my: 2 }}>
                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2 }}
                                            color='primary'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit()}
                                        >
                                            Actualizar
                                        </Button>
                                    </Box>
                                    :
                                    <Box sx={{ my: 2 }}>
                                        <Button
                                            sx={{ mb: 2, py: 2 }}
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitData)}
                                        >
                                            Guardar
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}