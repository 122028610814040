import { Box, Button, Grid, Icon, IconButton, InputBase, Paper, TextField, Typography, useTheme } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

export const Carousel = ( {onClick = undefined} ) => {
    const { palette } = useTheme()
  return (
    <Box 
        height={500} 
        bgcolor={'#444'} 
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        
    >
        <Grid container  justifyContent={'center'} alignItems={'flex-start'} gap={4} px={2}>
            <Grid item>
                <Box>
                    <h1 className='title' style={{color: 'white'}}>Consulte o seu resultado</h1>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', mt: 4, display: 'flex', alignItems: 'center', width: 320 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Digite o Nº do BI ou Nº de Inscrição"
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        </Paper>
                </Box>
            </Grid>
            <Grid item>
                <Box  onClick={onClick}>
                    <Box ml={-2.5}
                        component={Paper} bgcolor={palette.primary.main}
                        width={320} height={130} p={2} textAlign={'justify'}
                        sx={{':hover': {
                            transition: '1s',
                            transform: 'scale(1.1)'
                        }}}
                    >
                        <Box>
                            <i color='primary' className='fas fa-user-graduate'></i>
                        </Box>
                        <Box>
                            <Typography fontWeight={'bold'}>Inscreva-se</Typography>
                            <p> Faça já a sua inscrição e venha concorrer por uma vaga</p>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}
