import { setTokenLocalData, 
    cleanLocalData, 
    deleteTokenLocalData, 
    deleteUserLocalData, 
    getTokenLocalData,
    getUserLocalData, 
    setUserLocalData
} from "../LocalData"

export const useLocalData = () => {
    return {
        setTokenLocalData,
        cleanLocalData, 
        deleteTokenLocalData, 
        deleteUserLocalData, 
        getTokenLocalData,
        getUserLocalData, 
        setUserLocalData
    }
} 