import React from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
    Box, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

export function Escola() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [totalAccount, setTotalAccount] = React.useState(0);

    React.useEffect(() => {
        const findAllSchools = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await fetch('http://localhost:3333/schools', {
                    method: 'GET',
                    headers: { 'content-Type': 'application/json' },
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false);
            }
        }
        findAllSchools();
    }, [])

    return (
        <MenuDrawer page='Escolas'>
            <Head title='Escolas' />
            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <Box m={1} >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
                            <Grid item>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Pesquisar"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Link to='/academic/school/register' className='btn-success'>
                                    Cadastrar Escola <i className='fas fa-plus'></i>
                                </Link>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'>Nome</TableCell>
                                            <TableCell className='title-table text-center'>Director</TableCell>
                                            <TableCell className='title-table text-center'>Localização</TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length > 0 && data.map((item) => (
                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className='text-center'>{item.nome}</TableCell>
                                                    <TableCell className='text-center'>{item.diretor}</TableCell>
                                                    <TableCell className='text-center'>{item.localizacao}</TableCell>
                                                    <TableCell align="center">
                                                        <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                            <Link className='btn-icon' to={`/academic/school/show/${item.id}`}>
                                                                <i className="fa-solid fa-eye"></i>
                                                            </Link>
                                                            <Link to={`/academic/school/update/${item.id}`}>
                                                                <Typography className='btn-icon btn-edit'>
                                                                    <i className="fa-solid fa-edit"></i>
                                                                </Typography>
                                                            </Link>
                                                            <Typography className='btn-icon btn-edit'>
                                                                <i className="fa-solid fa-trash"></i>
                                                            </Typography>
                                                            <Link className='btn-icon' to={`/academic/school/print/${item.id}`}>
                                                                <i className="fa-solid fa-print"></i>
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <LinearProgress color='secondary' />
                                                </TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        {data.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <Typography width={250}>Lista de Escolas vazia</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(1 / Environment.ROW_LIMIT)}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}
