import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, studentServices, Head } from '../../shared'
import {
  Box, Divider, FormControl, Grid, Icon, IconButton, InputBase, InputLabel, LinearProgress, MenuItem, Pagination,
  Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams, Link } from 'react-router-dom';

export function Student() {
  const { palette } = useTheme();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalDatacount, setTotalDateCount] = React.useState(20);
  const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });

  const page = useMemo(() => {
    return SearchParmams.get('page') || ''
  }, [SearchParmams])

  React.useEffect(() => {
    studentServices.getAll(page, Environment.ROW_LIMIT)
      .then((data) => setData(data))
      .catch((error) => console.error(error))
  }, [page, data])

  const deletestudent = async (id) => {
    try {
      await studentServices.delOne(id);
      alert('Alunos eliminado com sucesso')
    } catch (error) {
      console.log(error);
    }
  }

  return (

    <MenuDrawer page='Alunos'>
      <Head title='Alunos' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <Box m={1} >
            <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
              <Grid item>
                <Paper
                  component="form"
                  sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}

                >
                  <InputBase
                    size='small'
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Pesquisar"
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item>
                <Box >
                  <FormControl sx={{ minWidth: 250, maxWidth: 250 }} size="small">
                    <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Relatório <Icon>print</Icon></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      size='small'
                      id="demo-simple-select"
                      value={''}
                      label="Relatório"
                      sx={{ py: .5 }}
                    >
                      <MenuItem value={10}>Janeiro</MenuItem>
                      <MenuItem value={20}>Fevereiro</MenuItem>
                      <MenuItem value={30}>Março</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item>
                <Link to='/people/students/register' className='btn-success'>
                  Cadastra Aluno <i className='fas fa-plus'></i>
                </Link>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                    <TableRow>
                      <TableCell className='title-table text-center'>Nº de Processo</TableCell>
                      <TableCell className='title-table text-center'>Nome</TableCell>
                      <TableCell className='title-table text-center'>Curso</TableCell>
                      <TableCell className='title-table text-center'>Turma</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 &&
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center'>{item.processo}</TableCell>
                          <TableCell className='text-center'>{item.nome}</TableCell>
                          <TableCell className='text-center'>{item.curso}</TableCell>
                          <TableCell className='text-center'>{item.turma}</TableCell>

                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Link className='btn-icon' to={`/people/students/show/${item.id}`}>
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                              <Link to={`/people/students/update/${item.id}`}>
                                <Typography className='btn-icon btn-edit'>
                                  <i className="fa-solid fa-edit"></i>
                                </Typography>
                              </Link>
                              <Typography className='btn-icon btn-edit' onClick={() => deletestudent(item.id)}>
                                <i className="fa-solid fa-trash"></i>
                              </Typography>
                              <Link className='btn-icon' to={`/people/students/update/${item.id}`}>
                                <i className="fa-solid fa-print"></i>
                              </Link>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    {data.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalDatacount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>

          </Box>
        </Box>

      </Box>

    </MenuDrawer>
  )
}
