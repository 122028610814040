import { API } from "./api"
import { api_services } from "./config"

export const GET_COURSES = (page=1, limit=15, filter='', token = '') => {
    return {
        // &page=${page}&limit=${limit}
        url: `${API}/courses?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json' ,
                Authorization: `Bearer ${token}`
            }
        }
    }
}
export const GET_COURSES_DELETED = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/courses/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
    }
}
export const getAllCourseJoinDiscipline = (filter='', token = '') => {
    return {
        url: `${API}/course_has_subjects?filter=${filter}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        }
    }
}
const POST_COURSES = async (data) => {
    try {
        const result = await api_services('/courses', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const createJoinCourseOfDiscipline = async (data) => {
    console.log({DATA: data});
    
    try {
        const result = await api_services('/course_has_subjects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
const updateCourse = async (id, data) => {
    console.log(id);
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getOne = async (id) => {
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async () => {
    try {
        const result = await api_services(`/courses/deleted_at`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async () => {
    try {
        const result = await api_services(`/courses/restore_all`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}

const restoreOneDeleted = async (id) => {
    try {
        const result = await api_services(`/courses/restore_one/${id}`, {
            method: 'GET'
        })

        console.log({result});
         
        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}
const DeleteOne = async (id) => {
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}

export const courseService = {
    POST_COURSES,
    getOne,
    DeleteOne,
    updateCourse,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted,
    getAllCourseJoinDiscipline,
    createJoinCourseOfDiscipline
}