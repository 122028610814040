import styles from './SeeMoreFunctionary.module.css'
import React from 'react'
import { Avatar } from '@mui/material'
import { Head } from '../../shared'
import { MenuDrawer } from '../../components'
import ImageAvatar from '../../assets/images/avatar/2.jpg'

export function SeeMoreAluno() {
    return (
        <MenuDrawer page='Alunos'>
            <Head title='Datalhes do Aluno' />
            <section sx={{ m: 2 }}>
                <div className={styles.firstBlock}>
                    <article className={styles.oneBloco}>
                        <div className={styles.bg}></div>
                        <Avatar src={ImageAvatar} alt='imagem-de-perfil' className={styles.avatar} />
                        <div className={styles.discriptioBloco}>
                            <p>Nome: <strong>Madrugada de Carvalho</strong></p>
                            <p>Número de Processo: <strong>5201</strong></p>
                            <p>Curso: <strong>Informática</strong></p>
                            <div>
                                <p>Graduado</p>
                                <p>UGS</p>
                            </div>
                        </div>
                    </article>
                </div>
                <article className={styles.twoBloco}>
                    <div className={styles.table}>
                        <h1 className='title'>Desempenho</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ borderRadius: '1rem !important' }}>Disciplina</th>
                                    <th>Semestre</th>
                                    <th>Média</th>
                                    <th>Tempo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Empreendedorismo</td>
                                    <td>1º</td>
                                    <td>
                                        <span className='nota-success'>14</span>
                                    </td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>SEAC</td>
                                    <td>1º</td>
                                    <td>
                                        <span className='nota-danger'>09</span>
                                    </td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TLP</td>
                                    <td>1º</td>
                                    <td>
                                        <span className='nota-success'>19</span>
                                    </td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>Educação Fisica</td>
                                    <td>1º</td>
                                    <td>
                                        <span className='nota-danger'>05</span>
                                    </td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </article>
            </section >
        </MenuDrawer >
    )
}
