import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Light } from './shared/theme'
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={Light}>
        <CssBaseline />
        <BrowserRouter>
            <App />

            <ToastContainer />
        </BrowserRouter>
    </ThemeProvider>
);
