import { API } from "./api"
import { api_services } from "./config"

export const GET_LOCATION = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/locations?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
    }
}

const POST_LOCATION = async (data) => {

    try {
        const result = await api_services('/locations', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const updateLocation = async (id, data) => {

    console.log(id);
    
    try {
        const result = await api_services(`/locations/${id}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getOne = async (id) => {
    try {
        const result = await api_services(`/locations/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/locations/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async () => {
    try {
        const result = await api_services(`/locations/restore_all`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreOneDeleted = async (id) => {
    try {
        const result = await api_services(`/locations/restore_one/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
const DeleteOne = async (id) => {
    try {
        const result = await api_services(`/locations/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const LocationService = {
    POST_LOCATION,
    getOne,
    DeleteOne,
    updateLocation,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted
}