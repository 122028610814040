import { createTheme } from "@mui/material";
import { grey, blue, yellow, orange } from "@mui/material/colors";

export const Light = createTheme({
    palette: {
        primary: {
            main: '#E19E06',
            light: '#ffd700',
            dark: '#c18702',
            contrastText: '#fff',
        },
        secondary: {
            main: '#1E310F',
            light: '#7ae523',
            dark: '#5a9c24',
            contrastText: '#fff',
        },
        // success: {
        //     main: '#00A28A',
        //     light: '#77B43F',
        // },
        
        background: {
            default: '#ebf0f6',
            paper: '#fff'
        }
    }
})