import { Box, Button, useTheme } from '@mui/material'
import React from 'react'
import { PnoWrap } from '../../../../components'
import { Link } from 'react-router-dom'

export const QuestionNational = ({setClose}) => {
    const {zIndex } = useTheme()
  return (
    <Box top={0} left={0}
    position={'absolute'} 
    zIndex={zIndex.drawer + 15} 
    width={'100vw'} height={'100vh'}
    sx={{background: 'rgba(55,55, 20, .1)'} }
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
   >
     <Box height={250} width={350} bgcolor={'white'} borderRadius={6} boxShadow={2} overflow={'hidden'}>
       <Box display={'flex'} justifyContent={'center'} py={4}>
         <i color='primary' style={{fontSize: 72}} className='fas fa-user-graduate'></i>
       </Box>
       <Box display={'flex'} gap={2} justifyContent={'center'} mb={2}>
           <PnoWrap text={'Clique na opção'} fontSize={18} fontWeight={'bold'} color='#77B43F'></PnoWrap>
       </Box>
       <Box display={'flex'} gap={2} justifyContent={'center'}>
           <Link to={'/inscription/1'}>
               <Button variant='contained' color='primary' onClick={setClose} >Nacional</Button>
           </Link>
           <Link to={'/inscription/2'}>
               <Button variant='contained' color='secondary' onClick={setClose} >Estrangeiro</Button>
           </Link> 
       </Box>
     </Box>
   </Box>
  )
}
