import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import {
    Autocomplete, Box, Button, Container, 
    FormControl, FormControlLabel, FormLabel, 
    Grid, 
    InputLabel, MenuItem, Paper, Radio, RadioGroup, 
    Select, 
    TextField, useTheme,
    Typography,
    Icon
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../form';
import { PnoWrap } from '../paragraphy'
import { countryServices, Head, studentServices } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';


const schema = yup.object().shape({
    photo: yup.string().optional(),
    full_name: yup.string().required('Campo obrigatorio').min(6, 'Nome Completo deve ter no minimo 6 caracteres'),
    birth_date: yup.string().required('Campo obrigatorio'),
    gender: yup.string().oneOf(['M', 'F']).required('Campo obrigatorio'),
    location: yup.string().required('Campo obrigatorio').min(4, 'Localição deve ter no minimo 4 caracteres'),
    civil_status_id: yup.string().required('Campo obrigatorio'),
    profession: yup.string().required('Campo obrigatorio').min(4, 'Profissão deve ter no minimo 4 caracteres'),
    country_id: yup.string().optional(),
    province_id: yup.string().required('Campo obrigatorio'),
    is_student: yup.string().oneOf(['SIM', 'NÃO']).optional().default('NÃO'),
    email: yup.string().required('Campo obrigatorio'),
    academic_formation: yup.string().required('Campo obrigatorio'),
    nif: yup.string().required('Campo obrigatorio'),
})

export function AddAndUpdateStudent() {
    const params = useParams()
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();

    const { palette } = useTheme();
    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            photo: undefined,
            full_name: "",
            birth_date: "",
            gender: "",
            location: "",
            civil_status_id: undefined,
            profession: '',
            country_id: undefined,
            province_id: undefined,
            is_employee: false,
            is_student: '',
            email: "",
            academic_formation: "",
            nif: '',
        }
    })

    const [Students, setStudents] = useState(null);
    const [countrySelected, setCountrySelected] = useState(null);
    const [icons, setIcons] = useState(null);

    useEffect(() => {
        countryServices.getAll().then((data) => {

            setCountries(data)
        }).catch(err => {
            console.log({ err });
        })
    }, [])

    useEffect(() => {
        if (params && params.id && typeof Number(params.id) === 'number') {
            setIsUpdate(true)
            studentServices.getOne(params.id).then((data) => {
                setStudents(data)
            }).catch(err => {
                console.log(err);
            })
        }
    }, [params, isUpdate])

    useEffect(() => {
        if (Students) {
            setValue('full_name', Students.full_name);
            setValue('birth_date', Students.birth_date);
            setValue('gender', Students.gender);
            setValue('email', Students.email);
            setValue('location', Students.location);
            setValue('academic_formation', Students.academic_formation);
            setValue('civil_status_id', Students.civil_status_id);
            setValue('profession', Students.profession);
            setValue('country_id', Students.country_id);
            setValue('is_employee', Students.is_employee);
            setValue('is_student', Students.is_student);

            let aux = countries[countries.findIndex((v) => {
                if (v.phone === Students.country_id?.toString()) {
                    return v
                }
            })]
            setCountrySelected(aux)
        }
    }, [Students, countrySelected]);

    const countryChange = (value) => {
        let aux = countries[countries.findIndex((v) => {
            if (v.label === value?.toString()) {
                return v
            }
        })]
        setCountrySelected(aux)
        setValue('country_id', countrySelected?.phone)
    }

    const submitData = async (data) => {
        try {
            const response = await studentServices.post({ ...data, photo: icons?.name ?? '' });
            navigate('/people/functionary')
            //  alert('user salvo com sucesso!!')
            // editContact({id: response.id, isUpdate: false, countries})
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <MenuDrawer page='Alunos'>
            <Head title={isUpdate ? 'Actualizar dados do Aluno' : 'Cadastra Aluno'} />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(submitData) : handleSubmit(submitData)}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='full_name'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Nome</FormLabel>
                                                    <Input 
                                                        placeholder='Digite seu nome' 
                                                        error={!!errors.full_name?.message} 
                                                        helpText={errors.full_name?.message} 
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        sx={{minWidth: 620}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='nif'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Nº de Identificação</FormLabel>
                                                    <Input 
                                                        placeholder='Digite seu nº de B.I ou Passaporte' 
                                                        error={!!errors.nif?.message} 
                                                        helpText={errors.nif?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange} 
                                                        sx={{minWidth: 300}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='birth_date'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Data de Nascimento</FormLabel>
                                                    <Input 
                                                        type={'date'} 
                                                        placeholder='Digite seu nº de B.I ou Passaporte' 
                                                        error={!!errors.birth_date?.message} 
                                                        helpText={errors.birth_date?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange} 
                                                        sx={{minWidth: 300}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='country_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Nacionalidade</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione o País</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione o País"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={10}>Angola</MenuItem>
                                                        <MenuItem value={20}>Brasil</MenuItem>
                                                        <MenuItem value={30}>Portugal</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Naturalidade</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione a Província</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione a Província"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={10}>Angola</MenuItem>
                                                        <MenuItem value={20}>Brasil</MenuItem>
                                                        <MenuItem value={30}>Portugal</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Residência</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Onde reside actualmente</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Onde reside actualmente"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={10}>Angola</MenuItem>
                                                        <MenuItem value={20}>Brasil</MenuItem>
                                                        <MenuItem value={30}>Portugal</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Port. Deficiência</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione a opção</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione a opção"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={true}>Sim</MenuItem>
                                                        <MenuItem value={false}>Não</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='gender'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Gênero</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione seu gênero</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione seu gênero"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={'M'}>Masculino</MenuItem>
                                                        <MenuItem value={'F'}>Femenino</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <PnoWrap color='grayText' fontWeight={'bold'} fonSize={14} text={'Informações de contacto '}/>
                            </Box>
                            <Grid container spacing={2}>
                               
                               

                            </Grid>

                            <Box my={2}>
                                <PnoWrap color='grayText' fontWeight={'bold'} fonSize={14} text={'Informações sobre a escola onde frequentou a 9º classe'}/>
                            </Box>
                            <Grid container spacing={2}>
                               <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='country_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Tipo de escola</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={10}>Publica</MenuItem>
                                                        <MenuItem value={20}>Privada</MenuItem>
                                                        <MenuItem value={30}>Comparticipada</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='nif'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Nome da escola</FormLabel>
                                                    <Input 
                                                        placeholder='Digite o nome da escola' 
                                                        error={!!errors.nif?.message} 
                                                        helpText={errors.nif?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange} 
                                                        sx={{minWidth: 300}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='nif'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Média final</FormLabel>
                                                    <Input 
                                                        placeholder='Digite o nota final' 
                                                        error={!!errors.nif?.message} 
                                                        helpText={errors.nif?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange} 
                                                        type={'number'}
                                                        sx={{minWidth: 300}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({field: {value, onChange}}) => (
                                                <Box display={'flex'} flexDirection={'column'} ><FormLabel>Município</FormLabel>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value??''}
                                                        label="Selecione"
                                                        onChange={onChange}
                                                        fullWidth
                                                        sx={{minWidth: 300}}
                                                    >
                                                        <MenuItem value={10}>Angola</MenuItem>
                                                        <MenuItem value={20}>Brasil</MenuItem>
                                                        <MenuItem value={30}>Portugal</MenuItem>
                                                    </Select>
                                                </FormControl></Box>
                                            )}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box>
                                        <Controller
                                            control={control}
                                            name='nif'
                                            render={({field: {value, onChange}}) => (
                                                <FormControl>
                                                    <FormLabel>Ano de conclusão</FormLabel>
                                                    <Input 
                                                        placeholder='Digite ano em que finalizou a 9ºclasse' 
                                                        error={!!errors.nif?.message} 
                                                        helpText={errors.nif?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange} 
                                                        sx={{minWidth: 300}}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>


                            </Grid>
                            <Box my={4}>
                                <Button variant='contained' endIcon={<Icon>save</Icon>}>Guardar info do candidato</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}