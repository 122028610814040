import React, { useState } from 'react'
import {Navbar} from './components/NAvbar'
import { Box, Button, CircularProgress, Container, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Input } from '../../../components';


const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatorio'),
  description: yup.string().optional(),
  years: yup.string().required('Campo obrigatorio'),
  status: yup.number().optional(),
  sort_order: yup.number().optional(),
})


export const FormOne = () => {
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema), defaultValues: {
        name: '',
        description: '',
        years: '',
        status: 0,
        sort_order: undefined,
    }
});

const [Loading, setLoading] = useState(false);


  return (
    <main className='page-container'>
      <Navbar />
      <Box component={Paper} m={2} borderRadius={2} width={'70%'} mx={'auto'}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                            <h1 className='title'>Inscrição</h1>
                            <p>Preencha todos os campos para fazer a inscrição</p>
                        </div>
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={()=>{}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Nome Completo</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Data de Nascimento</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                type="date"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Controller
                                        control={control}
                                        name='status'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Gênero</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.status}
                                                        label='Selecione'
                                                    >
                                                        <MenuItem key={1} value={1 ?? ''}>Masculino</MenuItem>
                                                        <MenuItem key={0} value={0 ?? ''}>Femenino</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Controller
                                        control={control}
                                        name='document_type'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Tipo de Documento</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label'>Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.status}
                                                        label='Selecione'
                                                    >
                                                        <MenuItem key={1} value={1 ?? ''}>Masculino</MenuItem>
                                                        <MenuItem key={0} value={0 ?? ''}>Femenino</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Número do Documento</InputLabel>
                                    <Controller
                                        control={control}
                                        name='document_number'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                type={'number'}
                                                placeholder={'Digite o número de documento'}
                                                error={!!errors.sort_order}
                                                helperText={errors.sort_order ? errors.sort_order.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Documento</InputLabel>
                                    <Controller
                                        control={control}
                                        name='document'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                type={'file'}
                                                placeholder={'Digite o número de documento'}
                                                error={!!errors.sort_order}
                                                helperText={errors.sort_order ? errors.sort_order.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} mb={2} >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        fullWidth
                                        type='submit'
                                        disabled={Loading}
                                        onSubmit={handleSubmit()}
                                        endIcon={
                                            Loading ?
                                                <CircularProgress size={'1rem'} /> :
                                                <Icon>save</Icon>
                                        }
                                        sx={{ py: 2, fontSize: 16 }}
                                    >
                                        Guardar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
    </main>
  )
}
